<template>
  <div>
    <c-table
      ref="table"
      title="위험물 및 지정수량"
      tableId="chemprod-inout"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      selection="multiple"
      rowKey="col3"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'danger',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'col1' },
          { index: 1, colName: 'col2' },
        ],
        columns: [
          {
            name: 'col',
            field: 'col',
            label: '위험물',
            child: [
              {
                name: 'col1',
                field: 'col1',
                label: '유별',
                align: 'center',
                sortable: true,
                style: 'width:100px',
              },
              {
                name: 'col2',
                field: 'col2',
                label: '성질',
                align: 'center',
                sortable: true,
                style: 'width:100px',
              },
              {
                name: 'col3',
                field: 'col3',
                label: '품명',
                align: 'left',
                sortable: true,
                style: 'width:200px',
              },
            ]
          },
          {
            name: 'col4',
            field: 'col4',
            label: '지정수량',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
        ],
        data: [
          { col1: '제1류', col2: '산화성 고체', col3: '1. 아염소산염류', col4: '50kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '2. 염소산염류', col4: '50kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '3. 과염소산염류', col4: '50kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '4. 무기과산화물', col4: '50kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '5. 브롬산염류', col4: '300kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '6. 질산염류', col4: '300kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '7. 요오드산염류', col4: '300kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '8. 과망간산염류', col4: '1000kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '9. 중크롬산염류', col4: '1000kg' },
          { col1: '제1류', col2: '산화성 고체', col3: '10. 그 밖에 행정안전부령으로 정하는 것\n11. 제 1호 내지 제 10호의 1에 해당하는 어느 하나 이상을 함유한 것', col4: '50kg, 300kg 또는 1000kg' },
          { col1: '제2류', col2: '가연성 고체', col3: '1. 황화린', col4: '100kg' },
          { col1: '제2류', col2: '가연성 고체', col3: '2. 적린', col4: '100kg' },
          { col1: '제2류', col2: '가연성 고체', col3: '3. 유황', col4: '100kg' },
          { col1: '제2류', col2: '가연성 고체', col3: '4. 철분', col4: '100kg' },
          { col1: '제2류', col2: '가연성 고체', col3: '5. 금속분', col4: '100kg' },
          { col1: '제2류', col2: '가연성 고체', col3: '6. 마그네슘', col4: '100kg' },
          { col1: '제2류', col2: '가연성 고체', col3: '7. 그 밖에 행정안전부령으로 정하는 것\n8. 제 1호 내지 제 7호의 1에 해당한느 어느 하아 시앙을 함유한 것', col4: '100kg 또는 500kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '1. 칼륨', col4: '10kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '2. 나트륨', col4: '10kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '3. 알킬알루미늄', col4: '10kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '4. 알킬리튬', col4: '10kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '5. 황린', col4: '20kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '6. 알칼리금속(칼륨 및 나트륨을 제외한다) 및 알칼리토금속', col4: '50kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '7. 유기금속화합물(알킬알루미늄 및 알킬리튬을 제외한다)', col4: '50kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '8. 금속의 수소화물', col4: '300kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '9. 금속의 인화물', col4: '300kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '10. 칼슘 또는 알루미늄의 탄화물', col4: '300kg' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '11. 그 밖에 행정안전부령으로 정하는 것\n12. 제1호 내지 제11호의 1에 해당하는 어느 하나 이상을 함유한 것', col4: '10kg, 20kg, 50kg 또는 300kg' },
        ],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
